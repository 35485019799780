import { useEffect, useState } from "react";
import { Col, Container, ProgressBar, Row } from "react-bootstrap";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { Link } from "react-router-dom";
import '../../styles/profile.css' 
import SidebarProfile from "../SidebarProfile";
import axios from "axios";
import { BASE_URL } from "../../App";
import ScrollTopButton from "../ScrollTop";
import Calendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons";




function Profile ({props , style}) {

  const [authDetails, setAuthDetails] = useState([]);
  const [myStats, setMyStats] = useState([]);
  const [walletBalance, setWalletBalance] = useState(0);

  const [authDetailsLoaded, setAuthDetailsLoaded] = useState(false);
  const [myStatsLoaded, setMyStatsLoaded] = useState(false);
  const [walletBalanceLoaded, setWalletBalanceLoaded] = useState(false);

  const [isLoading, setIsLoading] = useState(true); // Added isLoading state

  const token = localStorage.getItem('token');
  const axiosInstance = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      'ngrok-skip-browser-warning': 'any',
      Authorization: `Bearer ${token}`,
    },
  });

  const fetchAuthDetails = async () => {
    try {
      const response = await axiosInstance.get('/api/Auth');
      setAuthDetails(response.data);
      setAuthDetailsLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchMyStats = async () => {
    try {
      const response = await axiosInstance.get('/api/my-statistics');
      setMyStats(response.data);
      setMyStatsLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchCodes = async () => {
    try {
      const response = await axiosInstance.get('/api/my-codes');
      setWalletBalance(response.data.message);
      setWalletBalanceLoaded(true);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          fetchAuthDetails(),
          fetchMyStats(),
          fetchCodes(),
        ]);

        setAuthDetailsLoaded(true);
        setMyStatsLoaded(true);
        setWalletBalanceLoaded(true);
        setIsLoading(false); // Set isLoading to false after all data is loaded
      } catch (error) {
        console.error(error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);
  
      
      let percentageVideo = 0;
      if (myStats && myStats.numberOfVideos !== 0) {
          percentageVideo = Math.round((myStats.viewedVideos / myStats.numberOfVideos) * 100);
      }

      let percentageQuiz = 0;
      if (myStats && myStats.numOfAllQuizzes !== 0) {
          percentageQuiz = Math.round((myStats.numOfMyQuizzes / myStats.numOfAllQuizzes) * 100);
      }
      
   
      
      let percentageDegreess = 0;
      if (myStats && myStats.standardDegree !== 0) {
        percentageDegreess = Math.round((myStats.myDegrees / myStats.standardDegree) * 100);
      }
     

      const [selectedDate, setSelectedDate] = useState(new Date());

      const handleDateChange = (date) => {
        setSelectedDate(date);
      };

      useEffect(() => {
        window.scrollTo(0, 0);
      }, []);


      console.log(myStats);

    return (
        <>
                     {isLoading || !authDetailsLoaded || !myStatsLoaded || !walletBalanceLoaded ? 

           <div className={`main-loading ${style ? 'dark-mode' : ''}`}>
           <div className="loading-course"> 
         <h2>  .... جاري تحميل بياناتك</h2>
         </div>; 
         </div>
         :
//          <div className="profile">
//            <div className="profile-ground">
//                              <img src="/images/bg.3b55416e926d51a05f75.png" alt=""/> 
//                      </div>
//          <Container>
//              <Row>
//                  <Col lg={12}>
                    
//                      <div className="profile-user pt-5">
//                      <div className="title-profile mt-5 mb-5">
// <button className="button-prof" role="button">الملف الشخصي </button>


//                 </div>


//                         <Container>
//                              <Row>
//                                  <Col lg={8}>
//                                <div className="main-info">
//                                <div className="info">
//                                          <h2 className="mt-4">{authDetails.fname +' '+authDetails.lname}</h2>
//                                          <span className="line"></span>
//                                          <span className="number d-block">  {authDetails.email} <i className="fa-solid fa-phone ms-2"></i></span>
//                                          <span className="email d-block mt-2">{authDetails.phone} <i className="fa-solid fa-envelope ms-2"></i> </span>
//                                          <span className="email d-block mt-2">Id : {authDetails.id} </span>
//                                      </div>
//                                      <div className="info-course">
//                                          <span className="line"></span>
//                                     <div className="about-course">
//                                     <h2 className="analysis-name mb-5">احصائيات <span>كورساتك</span></h2>
//                                      <div className="Analysis d-flex align items center">
//                                          <Row>

//                                          <Col lg={4}>
//                                              <div className="box-3">
//                                          <CircularProgressbar value={percentageDegreess} text={`${percentageDegreess}%`} />
//                                          </div>
//                                          <div className="analysis-text"> متوسط درجاتك في الكويزات </div>
//                                          <div className="wallet d-flex">
//                                                <div > {myStats.standardDegree}  </div> 
//                                                      <div className="name"> من </div>                 
//                                                <div className="num-total"> {myStats.myDegrees}  </div> 
//                                                      </div>
//                                              </Col>
                                      
//                                              <Col lg={4}>
//                                              <div className="box-2">
//                                          <CircularProgressbar value={percentageQuiz} text={`${percentageQuiz}%`} />
//                                          </div>
//                                          <div className="analysis-text">عدد الكويزات اللي حليتها</div>
//                                          <div className="wallet d-flex mt-4">
//                                                <div> {myStats.numOfAllQuizzes}  </div> 
                                              
//                                                      <div className="name"> من </div>                 
//                                                <div  className="num-quiz"> {myStats.numOfMyQuizzes}  </div> 
//                                                      </div>
//                                              </Col>
                                             
//                                              <Col lg={4}>
//                                              <div className="box-1">
//                                          <CircularProgressbar value={percentageVideo} text={`${percentageVideo}%`} />
//                                          </div>
//                                          <div className="analysis-text">عدد الفديوهات اللي شوفتها</div>
//                                          <div className="wallet d-flex mt-1">
//                                                <div> {myStats.numberOfVideos}  </div> 
//                                                      <div className="name"> من </div>                 
//                                                <div className="num-video" > {myStats.viewedVideos}  </div> 
//                                                      </div>
//                                              </Col>
             
//                                          </Row>
                                         
                                   
//                                      </div>
                                 
     
                                                         
//                                     </div>
//                                  <div className="analysis-platform mb-5 mt-5">
//                                  <h2 className="analysis-name mb-5">احصائياتك علي  <span>المنصة</span></h2>

//                                      <div className="box">
//                                          <h3>عدد الحصص اللي اشترتها</h3>
//                                          <span className="one">{myStats.numOfMyWeeks}</span>
//                                          </div>

//                                          <span className="line"></span>
//                                      <div className="box">
//                                          <h3>عدد الأسئلة في الكويزات والواجبات اللي انت حليتها</h3>
//                                          <span className="two">{myStats.allSolvedQuizzes}</span>
//                                          </div>

//                                          <span className="line"></span>
//                                      <div className="box">
//                                      <h3>عدد الفديوهات اللي  شوفتها </h3>

//                                          <span className="three">{myStats.viewedVideos}</span>
//                                          </div>
//                                          <span className="line"></span>
//                                      <div className="box">
//                                          <h3>عدد مرات فتح الفديوهات </h3>
//                                          <span className="one">{myStats.numberOfWatchingVideos}</span>
//                                          </div>      

//                                          <span className="line"></span>
//                                          <div className="box">
//                                          <h3>عدد الأكواد اللي انت استخدمتها</h3>
//                                          <span className="four">{myStats.numberOfCodes}</span>
//                                          </div> 

//                                          <span className="line"></span>
//                                  </div> 
//                                      </div>
//                                </div>
//                                  </Col>
//                                  <Col lg={4}>
//                                      <SidebarProfile />
//                                  </Col>
//                              </Row>
//                              </Container>
//                          </div>
//                  </Col>
//              </Row>

//          </Container>
//      </div>
              <div className={`profile d-flex ${style ? 'dark-mode' : ''}`}>
              <SidebarProfile />
                  <div className="content-profile">
                    <Row className="mb-4">
                      <Col lg={12}>
                        <div className="title-main">
                          <h2> ملفك <span>الشخصي</span> </h2>
                        </div>
                      </Col>
                    </Row>
                      <Row className="align-items-center">
                        <Col lg={5} >
                          <div className="personal-data mb-4 mb-lg-0">
                            <img src="/images/OIP-removebg-preview..webp" alt="" loading="lazy"/>
                            <h1>  {authDetails.fname + " " + authDetails.lname} </h1>
                            <span className="mt-5"> {authDetails.email}       <FontAwesomeIcon icon={faPhone} className="ms-2" />
 </span>
                            <span> {authDetails.phone}        <FontAwesomeIcon icon={faEnvelope} className="ms-2" />
 </span>
                            <span> ID: {authDetails.id}   </span>
                            {/* <span>  كود السنتر :  {authDetails.center_code ? authDetails.center_code : 'لا يوجد'}</span> */}
                            <Link to="/editinfo">تعديل البيانات</Link>
                          </div>
                        </Col>
                        <Col lg={7}>
                      <div className="statistic">
                        <div className="title">
                          <img src="/images/2247-removebg-preview..webp" alt="" loading="lazy"/>
                          <h3>  مخابرات مستر <span>  محمد فودة </span>     </h3>
                          <img src="/images/2247-removebg-preview..webp" alt="" loading="lazy"/>

                        </div>
                        <div className="videos ">
                              <div className="number-video">
                              <span>عدد  <span>الفيديوهات</span>  اللي شوفتها</span>
                                   <span className="num">  {myStats.viewedVideos} <span> / </span> {myStats.numberOfVideos} </span>
                              </div>
                            <ProgressBar now={percentageVideo} label={`${percentageVideo}%`}  className="custom-progress-bar-video"   style={{ backgroundColor: '#ff000029' , height: '20px'}}/>
                        </div>
                        <div className="quizes">
                                <div className="number-quizes">
                                <span>عدد  <span>الكويزات </span>  المحلولة </span>
                                <span className="num"> {myStats.numOfMyQuizzes} <span> / </span> {myStats.numOfAllQuizzes} </span>
                                </div>
                            <ProgressBar now={percentageQuiz} label={`${percentageQuiz}%`}  className="custom-progress-bar-quizes"   style={{ backgroundColor: '#06b5d44f' , height: '20px'}}/>
                        </div >
                        <div className="avarage">
                          <div className="number-avarage">
                          <span>متوسط  <span>درجاتك</span>   في الكويزات </span>
                          <span className="num"> {myStats.myDegrees} <span> / </span> {myStats.standardDegree} </span>


                          </div>
                            <ProgressBar now={percentageDegreess} label={`${percentageDegreess}%`}  className="custom-progress-bar-avarage"   style={{ backgroundColor: '#deb88798' , height: '20px'}}/>
                        </div>
                      </div>
                        </Col>
                      </Row>
                      <Row className=" mt-4">
                        <Col lg={5}>
                          <div className="wallet">
                  <div className="title mb-3">
                          <h3> تابع مواعيدك علي  <span>المنصة</span></h3>
                            </div>   
                            <Calendar onChange={handleDateChange} value={selectedDate} />
 
                            {/* <div className="title">
                          <h3> تابع مواعيد علي  <span>المنصة</span></h3>
                            </div>
                              <img src="/images/wallet.png" alt=""/>
                              <div className="price">
                         
                                <span>رصيدك بالمحفظة  </span>
                                                                      
                                            <span> {walletBalance} جنيها</span>
                               
                              </div> */}
                          </div>
                        </Col>
                        <Col lg={7}>
                          <div className="number-of-statistic mt-lg-0 mt-3">
                            <div className="num-weeks">
                              <span> عدد  <span>الحصص</span> اللي انت اشتريتها </span>
                              <span> {myStats.numOfMyWeeks} </span>
                            </div>
                            <div className="num-questions">
                              <span> عدد  <span>الأسئلة</span>  اللي انت حليتها علي المنصة في الكويزات والواجبات</span>
                              <span> {myStats.allSolvedQuizzes} </span>
                            </div>
                            <div className="num-videos">
                              <span> عدد  <span>الفيديوهات</span> اللي انت شوفتها علي المنصة </span>
                              <span> {myStats.viewedVideos} </span>
                            </div>
                            <div className="num-openvideos">
                              <span>  عدد مرات مرات فتح <span>الفيديوهات</span> علي المنصة</span>
                              <span> {myStats.numberOfWatchingVideos} </span>
                            </div>
                           
                          </div>
                     
                        </Col>
                      </Row>
                    
                  </div>
              </div>

        }
       
        < ScrollTopButton />

        </>
    )
}
export default Profile;